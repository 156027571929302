import React, { Component } from "react";
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/common.css";
import "../../styles/videos.css";
import Grid from "@material-ui/core/Grid";
import Loader1 from "../loader/loader";
import {Image, Dimensions} from "react-native";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
import {Link} from 'react-router-dom';
import "../../../src/index.css";

class Movies extends Component {
  constructor(props){
    super(props);
    this.state= {
      homeVideos:[],
      premiumMovies:[],
      trendingMovies:[],
      featureMovies:[],
      banners:[],
      other:[],
      isLoading:false,
      
    }
  }

  fetchCategories =()=>{
    this.Setstate= {
      homeVideos:[],
      premiumMovies:[],
      trendingMovies:[],
      featureMovies:[],
      other:[],
      isLoading:false,
      
    }
    axios.get("https://mnm.nettvnepal.com.np/api/net-tv/movies", {
      headers: {
        'guest-secret-key': 'secretGuest123456789'
      }
    })
    .then(res => { if(!this.unMounted){
                     this.setState({
                         homeVideos:res.data.categories,
                         //other:res.data.movies,
                         trendingMovies:res.data.movies.trending_movies.movies,
                         premiumMovies:res.data.movies.premium_movies.movies,
                         featureMovies:res.data.movies.feature_movies.movies,
                         isLoading:true
                 })
             }
                     })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }

  handleShow = () => {
    this.setState({
        show:true
    })

  }
  handleClose = () =>{
      this.setState({
        show:false
      })
  }

  componentDidMount = () =>{
    if(sessionStorage.getItem('homeVideos') && sessionStorage.getItem('trendingMovies') && sessionStorage.getItem('premiumMovies') && sessionStorage.getItem('featureMovies')){
      // console.log("using data from session storage");
      sessionStorage.getItem('homeVideos') && sessionStorage.getItem('trendingMovies') && sessionStorage.getItem('premiumMovies') && sessionStorage.getItem('featureMovies') && this.setState({
            homeVideos: JSON.parse(sessionStorage.getItem('homeVideos')),
            trendingMovies: JSON.parse(sessionStorage.getItem('trendingMovies')),
            premiumMovies: JSON.parse(sessionStorage.getItem('premiumMovies')),
            featureMovies: JSON.parse(sessionStorage.getItem('featureMovies')),
            isLoading:true
          })
    }
    else{
      this.fetchCategories();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    sessionStorage.setItem('homeVideos', JSON.stringify(nextState.homeVideos));
    sessionStorage.setItem('trendingMovies', JSON.stringify(nextState.trendingMovies));
    sessionStorage.setItem('premiumMovies', JSON.stringify(nextState.premiumMovies));
    sessionStorage.setItem('featureMovies', JSON.stringify(nextState.featureMovies));
    
  }
  

  componentWillUnmount = () => {
   this.Unmounted = true
}

  render() {
    
    const{homeVideos, premiumMovies,trendingMovies,featureMovies, other, isLoading} = this.state;
    // console.log("object", homeVideos)
    {/**Category Movies */}
    const getVideos = homeVideos.map(d =>
      <div className="textChange" style={{width: '100%'}}>
      <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', fontWeight:'bold', marginBottom:'10px'}} >{d.name}</div>
            <div className="row">
            {d.movies.movies.map(a=>
              <div className="col-lg-2 col-md-2 col-sm-3 col-4 ">
              <div className="geetInfo1">
              <Link to={{
                pathname: "/movie/"+a.name,
                state:{ 
                  data: a}// your data array of objects
              }}
              style={{textDecoration:'none'}}
              >
              <img className="image-and-resize"
                    onClick={ () => this.handleShow()}
                    alt = {a.name}
                    style={{height:Dimensions.get('window').height/3, width:'100%'}}
                    src={
                     a.logo
                    }
                    
                    //resizeMode="stretch"
                    /> <div className="fillContent">
                    {a.purchase_type === "free" ? 
                    <p style={{marginBottom:'0px'}}>{a.purchase_type}</p> : a.purchase_type === "regular"?<p style={{marginBottom:'0px'}}>PACKAGE</p>: a.price.map((a) => 
                    <div style={{textAlign:'right'}} >
                       <p style={{marginBottom:'0px'}}> {a.currency} {a.price} </p>
                    </div>
                    )}
                    </div>
                                  <div className="truncate cardContent" style={{textAlign:'center', color:'#9fa1a3'}}><p >{a.name}</p></div>
                                  </Link>
                                  </div>
                {/** <p className="text-capitalize ">{a.genre}</p>  */}
              </div> 
              )}
              </div>
              <hr/>
          </div>
          )
          {/**Premium Movies */}
                const getVideos2= premiumMovies.map(d =>
                  
                  <div className="col-lg-2 col-md-2 col-sm-3 col-4 ">
                        <div className="textChange geetInfo1">
                        <Link to={{
                          pathname: "/movie/"+d.name,
                          state:{ 
                            data: d}// your data array of objects
                        }}
                        style={{textDecoration:'none'}}
                        >
                    <img className="image-and-resize"
                    onClick={ () => this.handleShow()}
                    alt = {d.name}
                    style={{height:Dimensions.get('window').height/3, width:'100%'}}
                    src={
                     d.logo
                    }
                    //resizeMode="stretch"
                    />  <div className="fillContent">
                    {d.purchase_type === "free" ? 
                    <p style={{marginBottom:'0px'}}>{d.purchase_type}</p> : d.price.map((a) => 
                    <div style={{textAlign:'right'}} >
                       <p style={{marginBottom:'0px'}}> {a.currency} {a.price} </p>
                    </div>
                    )}
                    </div>
                       {/**   <img src={d.logo} style={{ height:'200px'}}/>  */}
                          <div className="truncate cardContent" style={{textAlign:'center', color:'#9fa1a3'}}><p >{d.name}</p></div>
                          </Link>
                          </div>
                          {/** <p className="text-capitalize textChange">{d.genre}</p> */}
                        </div>    
                      )

                  {/**featured Movies */}
                 const featured= featureMovies.map(d =>
                  
                        <div className="textChange col-lg-2 col-md-2 col-sm-3 col-4 ">
                              <div className="geetInfo1">
                              <Link to={{
                                pathname: "/movie/"+d.name,
                                state:{ 
                                  data: d}// your data array of objects
                              }}
                              style={{textDecoration:'none'}}
                              >
                              <img className="image-and-resize"
                              onClick={ () => this.handleShow()}
                              alt = {d.name}
                              style={{height:Dimensions.get('window').height/3, width:'100%'}}
                              src={
                               d.logo
                              }
                              //resizeMode="stretch"
                              />  <div className="fillContent">
                              {d.purchase_type === "free" ? 
                              <p style={{marginBottom:'0px'}}>{d.purchase_type}</p> : d.price.map((a) => 
                              <div style={{textAlign:'right'}} >
                                 <p style={{marginBottom:'0px'}}> {a.currency} {a.price} </p>
                              </div>
                              )}
                              </div>
                                <div className="truncate cardContent" style={{textAlign:'center', color:'#9fa1a3'}}><p >{d.name}</p></div>
                                </Link>
                                </div>
                                {/**  <p className="text-capitalize textChange">{d.genre}</p> */}
                              </div>    
                            )

                             {/**Trending Movies */}
                            const trending= trendingMovies.map(d =>
                  
                              <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                    <div className="textChange geetInfo1">
                                    <Link to={{
                                      pathname: "/movie/"+d.name,
                                      state:{ 
                                        data: d}// your data array of objects
                                    }}
                                    style={{textDecoration:'none'}}
                                    >
                                    <img className="image-and-resize"
                                    onClick={ () => this.handleShow()}
                                    alt = {d.name}
                                    style={{height:Dimensions.get('window').height/3, width:'100%'}}
                                    src={
                                     d.logo
                                    }
                                    //resizeMode="stretch"
                                    />  <div className="fillContent">
                                    {d.purchase_type === "free" ? 
                                    <p style={{marginBottom:'0px'}}>{d.purchase_type}</p> : d.price.map((a) => 
                                    <div style={{textAlign:'right'}} >
                                       <p style={{marginBottom:'0px'}}> {a.currency} {a.price} </p>
                                    </div>
                                    )}
                                    </div>
                                      <div className="truncate cardContent" style={{textAlign:'center', color:'#9fa1a3'}}><p >{d.name}</p></div>
                                      </Link>
                                      </div>
                                      {/** <p className="text-capitalize textChange">{d.genre}</p>  */}
                                    </div>    
                                  )

    // Modal Starts
    if(this.state.show){
      return (
        <div className="modal ">
        <Modal
         show={this.state.show} onHide={()=>this.handleClose()}
         centered
         
        >
        <Modal.Header closeButton >
        <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
        <div className = "row">
        <div className="col-4">
        </div>
        <div className="col-4">
        <div className = "row">
        <div className="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
        <img src = {playstore} /> 
        </a>
        </div>
        <div className="col-6">
        <img src = {appstore}/>
        </div>
        </div>
        </div>
        <div className="col-4">
        </div>
        </div>
        <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
        <p style={{textAlign:'center'}}>Scan QR Code</p>
        <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
        <div className="row">
        <div className="col-2 "><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
        </div>
        <div className="col-2">
        </div>
        <div className="col-2">
        </div>
        </div>
        </div>
        <div className="col-4"></div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={()=>this.handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    )
    }
    //Modal Ends
 

    if(isLoading){
    return (
      <React.Fragment>
      {/**Premium Movies */}
      <Grid container spacing={4} className="homepageGridContainer">
      <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', fontWeight:'bold', marginBottom:'10px'}} >PREMIUM</div>
      <div className="row">
      {getVideos2}
      </div>
      </Grid>
      <div className = "underline">
      <hr/>
      </div>

      {/**Trending Movies */}
      <Grid container spacing={4} className="homepageGridContainer">
      <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', fontWeight:'bold', marginBottom:'10px'}} >TRENDING</div>
      <div className="row">
      {trending}
      </div>
      </Grid>
      <div className = "underline">
      <hr/>
      </div> 

      {/**featured Movies */}
      <Grid container spacing={4} className="homepageGridContainer">
      <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', fontWeight:'bold', marginBottom:'10px'}} >FEATURED</div>
      <div className="row">
      {featured}
      </div>
      </Grid>
      <div className = "underline">
      <hr/>
      </div>
     
      <Grid container spacing={4} className="homepageGridContainer">
              {/**Category Movies */}
              {getVideos}
              </Grid>
              
       </React.Fragment>
    );
    }
    else return <Loader1/>
  }
}

export default Movies;
