import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { AuthButton } from "../login/auth";
// import VideoTabs from "../videoTabs/videoTabs";
import "../../styles/channel.css";
import { ChannelProfile } from "../../connectivity/channel/api.channelProfile";
import { IconButton } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import { CategoriesVideosList } from "../../connectivity/home/api.home";
import { getCategoryId } from "../videos/videoList";
import UserPhoto from "../user/userPhoto";
import channel_placeholder from "../../assets/default_placeholders/default_audio.jpg";

class Channel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelData: [],
      channelDetails: []
    };
  }

  componentDidMount() {
    // console.log(document.getElementsByClassName("MuiContainer-root"));
    ChannelProfile().then(res => {
      // console.log(res);
      this.setState({ channelData: res.data.data });
      this.setState({ channelDetails: res.data });
    });
  }
  videoClicked = (id, catID) => {
    localStorage.setItem("ClickedCategoryVideoID", catID);
    CategoriesVideosList(id);
    getCategoryId(catID);
  };

  render() {
    return (
      <Container fixed>
        {/* <Typography
            component="div"
            style={{ backgroundColor: "#cfe8fc", height: "100vh" }}
          /> */}
        <div className="channelBanner">
          <img
            src={require("../../assets/demoChannelBanner.jpg")}
            alt="Channel Banner"
            onError={
              e => {
                e.target.src = channel_placeholder
              }
            }
          />
        </div>

        <Container fixed className="channelInfoWrapper">
          <Grid container spacing={3} className="channelHeader">
            <Grid item xs={12} sm={9}>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  {/* <img src={require("../../assets/dummyChannelLogo.jpg")} /> */}
                  <UserPhoto />
                </Grid>
                <Grid item xs={10} className="channelTitle">
                  <Typography variant="h4" gutterBottom>
                    {this.state.channelDetails.name || "DotaVerse"}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {this.state.channelDetails.total_subscribers} Subcribers
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} className="subscribeBtn">
              <Button variant="contained">Subscribe</Button>
            </Grid>
          </Grid>

          <AuthButton />
        </Container>
        <Typography variant="h5">My Videos</Typography>
        <Grid container className="video-grid-container">
          {this.state.channelData.map(channelItem => (
            <Grid
              key={channelItem.id}
              id={channelItem.id}
              item
              //onClick={VideoById.bind(this, videoItems.id)}
            >
              <Link to={`/player/${channelItem.id}`} color="inherit">
                <div className="thumbnail">
                  <img
                    src={channelItem.background}
                    alt={channelItem.title}
                    onClick={() =>
                      this.videoClicked(channelItem.id, channelItem.category_id)
                    }
                    onError={
                      e => {
                        e.target.src = channel_placeholder
                      }
                    }
                  />
                  <Typography
                    variant="overline"
                    className="over-thumbnail duration"
                  >
                    {channelItem.meta == null ? "0" : channelItem.meta.duration}
                  </Typography>
                  <div className="over-thumbnail view">
                    <i className="material-icons">remove_red_eye</i>
                    <Typography variant="overline">
                      {channelItem.view_count}
                    </Typography>
                  </div>
                </div>
              </Link>
              <div className="video-title">
                {/* <div> */}
                <Typography
                  variant="h6"
                  color="inherit"
                  onClick={() =>
                    this.videoClicked(channelItem.id, channelItem.category_id)
                  }
                >
                  <Link to={`/player/${channelItem.id}`} color="inherit">
                    {channelItem.title}
                  </Link>
                </Typography>
                {/* </div> */}
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              </div>

              <div className="video-title publisher">
                <div className="likes">
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                  <Typography variant="body2">4.2k</Typography>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
}

export default Channel;
