import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

import { AddComment } from "../../components/player/addComments";
import { AddCommentApi } from "../../connectivity/player/api.addComments";
import { toast } from "react-toastify";

import "../../styles/details.scss";
import { UserContext } from "../../components/userInfo";

class AddCommentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: null,
      commentId: null,
      videoId: null,
      isSubmitted: false
    };
  }

  getComments(comments) {
    this.setState({
      comment: comments,
      commentId: this.props.commentId,
      videoId: this.props.player,
      isSubmitted: true
    });
  }

  componentDidMount() {}

  componentDidUpdate = newState => {
    newState = this.state;
    if (newState.videoId !== null && newState.comment !== null) {
      AddCommentApi(
        newState.videoId,
        newState.comment,
        newState.commentId
      ).then(() => toast("Your Comment has been posted."));
    }
  };

  render() {
    return (
      <div>
        <AddComment getComments={this.getComments.bind(this)} />
        {this.state.isSubmitted ? (
          <Typography component={"span"}>
            <div className="commentSection">
              <div className="userImage">
                <img
                  src={require("../../assets/dummyChannelLogo.jpg")}
                  alt="user"
                />
              </div>

              <div className="comments">
                <UserContext.Consumer>
                  {data => <div className="commenter">{data.first_name}</div>}
                </UserContext.Consumer>

                <div className="commentBody">{this.state.comment}</div>
              </div>
            </div>
          </Typography>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default AddCommentContainer;
