import React, { Component } from "react";
import { Subscribe } from "../../connectivity/api.subscribe";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "../../styles/details.scss";
import "../../styles/textResponsive.scss";
import { getVideoById } from "../../connectivity/player/api.videoById";
import { toast } from "react-toastify";

class ChannelInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {
        // console.log(
        //   "THE props we received in channelINFO.jsx",
        //   this.props.channelDetails
        // )
        }
        <Grid container spacing={2} className="channelName">
          <Grid item xl={3} lg={4} sm={2} xs={3}>
            {this.props.channelDetails == undefined ? (
              ""
            ) : (
              <img src={this.props.channelDetails.cover} alt="User" />
            )}
          </Grid>
          <Grid item xl={9} lg={8} sm={10} xs={9}>
            <Typography variant="h6">
              {this.props.channelDetails == undefined
                ? "Loading..."
                : this.props.channelDetails.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {this.props.channelDetails == undefined
                ? "Loading..."
                : this.props.channelDetails.total_subscribers}
              subs
            </Typography>

            <Button
              variant="contained"
              color="primary"
              // onClick={() => handleClick(this.props.channelDetails.id)}
            >
              Subscribe
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ChannelInfo;
