import React, { Component } from "react";
import { getUserInfo } from "../connectivity/api.userInfo";
// import AddCommentContainer from "../containers/player/addCommentContainer";
import Navbar from "../containers/navbar";
// import { Button } from "@material-ui/core";

export const UserContext = React.createContext();

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      id: null,
      email: "",
      mobile: "",
      profile_pic: ""
    };
  }

  componentWillMount() {
    getUserInfo().then(data => {
      let userData = data;
      this.setState({
        first_name: userData.data.first_name,
        last_name: userData.data.last_name,
        id: userData.data.id,
        email: userData.data.email,
        mobile: userData.data.mobile
        // address: userData.data.user_detail.address,
        // links: userData.data.user_detail.links
      });
      //   console.log(userData.data);
    });
  }
  render() {
    // console.log(this.state);
    return (
      <UserContext.Provider value={this.state}>
        <Navbar toggleTheme={this.props.onToggleDark} />
      </UserContext.Provider>
    );
  }
}

export default UserInfo;
