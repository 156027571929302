import { getBaseRequestConfig } from "../baseRequestConfig";
import asyncFetch from "../async-fetch";

export async function AddCommentApi(video_id, body, parent_id) {
  // console.log(video_id, body, parent_id);
  const url =
    process.env.REACT_APP_API_URL + "/videos/" + video_id + "/comments";

  const baseRequestConfig = getBaseRequestConfig();

  //   let commentsdata = FormData();
  //   commentsdata.append("body", body);
  //   commentsdata.append("parent_id", parent_id);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    method: "POST",
    data: {
      body: body,
      parent_id: parent_id
    }
  });

  const response = await asyncFetch(url, requestConfig);

  return await response;
}
