import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import Axios from "axios";
import "../../styles/login.scss";
import RegisterNTC from "./registerNTC";
import NormalSignUp from "./normalSignUp";
// import { SignUp } from "../../connectivity/api.authentication";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = formErrors => {
  let valid = true;

  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: false,
      first_name: null,
      last_name: null,
      email: null,
      //mobile: null,
      password: null,
      password_confirmation: null,
      showToast: false,
      phone_signup:false,
      showClick:false,
      formSubmitted: false,
      formErrors: {
        first_name: "",
        last_name: "",
        email: "",
        //mobile: "",
        password: "",
        password_confirmation: "",
        tc: ""
      }
    };
  }

  handleClick = () =>{
    this.setState({
    showClick:true
  })
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    // console.log("Name: ", name);
    // console.log("value: ", value);

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      // case "mobile":
      //   formErrors.mobile.value.length < 10 ||
      //   formErrors.mobile.value.length > 10
      //     ? "Invalid Mobile Number"
      //     : "";
      //   break;
      case "password_confirmation":
        // console.log("form conform", value === this.state.password);
        formErrors.password_confirmation =
          value === this.state.password ? "" : "Password does not match";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimun 6 characters required" : "";
        break;
      case "tc":
        formErrors.tc = this.state.accept ? "" : "You have to accept the terms and conditions.";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  };

  handleSubmit = e =>{
    e.preventDefault();
    this.setState({
      phone_signup:true
    })

  }

  handleSignUpSubmit = e => {
    e.preventDefault();
    this.state.formSubmitted = true;
    // console.log(this.state.accept);
    // console.log("form accepted ", this.state.formSubmitted);
      if (!this.state.accept) {
      // toast("Please Accept to the terms and condition");
      return;
    }
    if (this.state.formErrors.password_confirmation.length > 0) {
      toast("Password did not match");
      return;
    }
    // console.log("sign up");
    // console.log(`
    //    --SUBMITTING--
    //     First Name: ${this.state.first_name}
    //     Last Name: ${this.state.last_name}
    //     Email: ${this.state.email}
    //     Password: ${this.state.password}
    //    `);

    let formdata = new FormData();
    formdata.append("first_name", this.state.first_name);
    formdata.append("last_name", this.state.last_name);
    formdata.append("email", this.state.email);
    //formdata.append("mobile", this.state.mobile);
    formdata.append("password", this.state.password);
    formdata.append("password_confirmation", this.state.password_confirmation);

    // console.log(Array.from(formdata));

    if (
      this.state.formErrors.email.length > 0 ||
      this.state.formErrors.password.length > 0 ||
      this.state.password_confirmation !== this.state.password
    ) {
      // console.log("error");
      toast("Sign up Error");
    } else {
      // return SignUp(
      //   this.state.firstName,
      //   this.state.lastName,
      //   this.state.email,
      //   this.state.mobile,
      //   this.state.password
      // );
      return Axios({
        url: 'https://mnm.nettvnepal.com.np/api/net-tv/otp/email',
        method: "POST",
        headers: {
          authorization: ""
        },
        data: formdata
      }).then((result)=>{
        let responseJson = result;
        // console.log("result from api:", responseJson);
        // console.log("status",this.state.status )
        if(responseJson.userData){
            sessionStorage.setItem('userData', JSON.stringify(responseJson) );
        }
        if(responseJson){
              this.setState({
                statusCheck:true,
                responseSuccess: responseJson.status,
                message:responseJson.data.message,
                responseError:responseJson.data.error
              })
              localStorage.setItem("message", (responseJson.data.message))
            }})
            .catch((error) => {
              this.setState({
                error: error.response.data.message
              })
              // console.log(error.response.data.message)
          });
    }
  };

  accept = () => {
    this.setState({
      accept: !this.state.accept
    });
  };

  render() {
    const { formErrors } = this.state;
    if(this.state.phone_signup){
      return <RegisterNTC/>
    }
    else if(this.state.responseSuccess === 200 && this.state.showClick === true){
      return <NormalSignUp/>
    }
    else if(this.state.responseSuccess === 400 && this.state.showClick === true){
      return "hello"
    }
    return (
      <React.Fragment> 
      <p style={{backgroundColor:'#e93e22', }}>
       {this.state.error}
       </p>
        <form
          onSubmit={this.handleSignUpSubmit}
          id="signUpForm"
          required
          autoComplete="off"
        >
          <Typography variant="h4">SIGN UP</Typography>
          <TextField
            id="outlined-with-placeholder"
            label="First Name"
            placeholder="Fist Name"
            className={
              formErrors.first_name.length > 0 ? "error" : "fields"
            }
            className="fields"
            margin="normal"
            name="first_name"
            type="first_name"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {/* {formErrors.firstName.length > 0 && (
                  <Typography variant="caption" className="errorMessage">
                    {formErrors.firstName}
                  </Typography>
                )} */}
          <TextField
            id="outlined-with-placeholder"
            label="Last Name"
            // placeholder="Email or Phone Number"
            // className={
            //   formErrors.lastName.length > 0 ? "error" : "fields"
            // }
            className="fields"
            margin="normal"
            name="last_name"
            type="last_name"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {/* {formErrors.lastName.length > 0 && (
                  <Typography variant="caption" className="errorMessage">
                    {formErrors.lastName}
                  </Typography>
                )} */}

          <TextField
            id="outlined-with-placeholder"
            label="Email"
             placeholder="Email or Phone Number"
            className={formErrors.email.length > 0 ? "error" : "fields"}
             className="fields"
            margin="normal"
            name="email"
            type="email"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {formErrors.email.length > 0 && (
            <Typography variant="caption" className="errorMessage">
              {formErrors.email}
            </Typography>
          )}
         {/** <TextField
            id="outlined-with-placeholder"
            label="Mobile Number"
            // placeholder="Email or Phone Number"
            //  className={formErrors.mobile.length > 0 ? "error" : "fields"}
            className="fields"
            margin="normal"
            name="mobile"
            type="mobile"
            variant="outlined"
            onChange={this.handleChange}
            required
          /> */}
          <TextField
            id="outlined-with-placeholder"
            label="Password"
             placeholder="Password"
            className={formErrors.password.length > 0 ? "error" : "fields"}
            className="fields"
            type="password"
            autoComplete="current-password"
            margin="normal"
            name="password"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {formErrors.password.length > 0 && (
            <Typography variant="caption" className="errorMessage">
              {formErrors.password}
            </Typography>
          )}
          <TextField
            id="outlined-with-placeholder"
            label="Confirm Password"
            placeholder="Password"
            className={
              formErrors.password_confirmation.length > 0 ? "error" : "fields"
            }
            className="fields"
            type="password"
            autoComplete="current-password"
            margin="normal"
            name="password_confirmation"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
         {formErrors.password_confirmation && (
          <Typography variant="caption" className="errorMessage">
            {formErrors.password_confirmation}
          </Typography>
        )} 
          {/* {formErrors.password_confirmation.length > 0 ? (
            <Typography variant="caption" className="errorMessage">
              Password Does Not Match
            </Typography>
          ) : (
            ""
          )} */}
          <div className="accept-terms">
            <Checkbox
              value="checkedC"
              inputProps={{
                "aria-label": "uncontrolled-checkbox"
              }}
              className={this.state.accept ? "ticked" : ""}
              onClick={this.accept}
              required
            />
            <Typography variant="caption">
              I Accept The Terms and Conditions
            </Typography>
            {this.state.formSubmitted && !this.state.accept && (
              <Typography variant="caption" className="errorMessage">
                You have to accept the terms and conditions
              </Typography>
            )} 
          </div>
          <div className="login">
            <Button
              variant="contained"
              className="momoOrangeBtn"
              type="submit"
              onClick={this.handleClick}
            >
              Sign Up
            </Button>
            
          </div>
        </form>
        {/* <Button
              variant="contained"
              color="secondary"
              className="loginBtn"
              type="submit"
              onClick={this.handleSubmit}
              
            >
              Sign Up With Phone
            </Button> */}
      </React.Fragment>
    );
  }
}

export default SignUpForm;
