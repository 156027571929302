import React, {Component} from "react";
import Parser from "html-react-parser";
import "../../styles/paymentHTML.scss";

class Fonepay extends Component {
    render() {
        return (
            <React.Fragment>
                {
                // console.log(localStorage.getItem("fonepayHTML"))
                }
                <div 
                    dangerouslySetInnerHTML={{
                        __html: localStorage.getItem("fonepayHTML")
                    }}>
                </div>
            </React.Fragment>
        )
    }
}

export default Fonepay;