
import React, {useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, fade } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import SearchResult from "./searchResult";
import Axios from "axios";

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: theme.shape.borderRadius,
    // color: fade(theme.palette.common.white, 0.75),
    color: "rgba(0,0,0,0.65)",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto",
      width: "100%",
      border: "1.35px solid rgba(0,0,0,0.5)",
      backgroundColor: "rgba(0,0,0,0.05)"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // color: fade(theme.palette.common.white, 0.75)
    color: "rgba(0,0,0,0.65)"
  },
  inputRoot: {
    color: "inherit",
    width: "75%"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%"
      }
    },
    [theme.breakpoints.down("xs")]: {
      width: 135,
      "&:focus": {
        width: 135
      }
    }
  },
  custom: {
    margin: 3,
    alignItems: "center",
    width: "50%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.08)"
  }
}));

const Search = props => {
  const classes = useStyles();
  //   const theme = useTheme();

  const filters = [
    // {
    //   value: "video",
    //   label: "Video",
    //   },
    //   {
    //     value: "channel",
    //     label: "Channel"
    // }
  ];
  const [values, setValues] = React.useState({
    text: "",
    filter: "video"
  });

  // const [results, searchResult] = React.useState("No Results Found");
  const handleChange = text => event => {
    // console.log("text", text)
    setValues({ ...values, [text]: event.target.value });
  };
  const[data1, setData1] = useState();
  const[searchData, setSearchData] = useState();
  
  return (
    <React.Fragment>
    {/* {console.log("data1", data1)} */}
  <div>
    </div>   
    <div className="search-and-bar">
                {/* <a href="https://voting.momoappnepal.com/" target="_blank"><img
                  src={require("../assets/saregamabanner.png")}
                  alt="video chautari logo"
                  className="mainlogo"
                /></a> */}
    {/* <form className={classes.search} > */}
    
      {/* <InputBase
        placeholder="Search hello by Song, Movies, Music Videos ...."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        onChange={handleChange("text")}
        inputProps={{ "aria-label": "search" }}
      /> */}
     {/** <TextField
      {/* <TextField
        id="searchFilter"
        select
        //label="Select"
        className={classes.custom}
        value={values.filter}
        onChange={handleChange("filter")}
        disabled
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
        margin="dense"
      >
        {filters.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField> */}
     {/* <Link to ={{
        pathname:"/searchResult/"+values.text,
        sampleParam:values.text
        
      }}> 
      <IconButton type="submit" size="medium">
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
      </IconButton>
     </Link> 
    </form> */}
    
    </div>
   {/** <SearchResult example = {data1}/> */}
    </React.Fragment>
    
  );
};

export default Search;
