import { getBaseRequestConfig } from "../baseRequestConfig";
import asyncFetch from "../async-fetch";

export async function GetCategoryList(id) {
  const url = process.env.REACT_APP_API_URL + "/categories/" + id;

  const baseRequestConfig = getBaseRequestConfig();

  const response = await asyncFetch(url, baseRequestConfig);

  return await response;
}
