import React, { Component } from "react";
import axios from "axios";
import "../../styles/news.css";
import Loader1 from "../loader/loader";
import logo from '../../assets/LogoWhite.png';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      isLoading: false,
    };
  }

  fetchPosts = () => {
    axios
      .get("https://mnm.nettvnepal.com.np/api/net-tv/news?page=1")
      .then(res => {
        if (!this.Unmounted) {
          this.setState({
            news: res.data.data,
            showMore: false,
            expanded: false,
            itemsToShow: 8,
            isLoading: true
          });
        }
      });
  };

  componentDidMount = () => {
    this.fetchPosts();
  };

  componentWillUnmount = () => {
    this.Unmounted = true;
  };

  handleClick = () => {
    //this.setState({showMore: true})
    // console.log("Show", this.state.itemsToShow);
    this.state.itemsToShow === 8
      ? this.setState({ itemsToShow: this.state.news.length, expanded: true })
      : this.setState({ itemsToShow: 8, expanded: false });
  };

  render() {
    const { news, isLoading } = this.state;
    // console.log("hey", news);
    //const numberOfItems = this.state.showMore ? news.length : 8
    const data = news.slice(0, this.state.itemsToShow).map(d => (
      <div className=" textChange col-md-3 col-sm-4 col-xs-4 py-3 px-3">
      <a style={{ color: "black" }} href={d.link} target="_blank">
      {d.image === ""?<img src='http://arthacdn.prixa.net/media/albums/samsad_L3py6gqJOW.jpg' style={{ height: "195px" }} />:
      <img src={d.image} style={{ height: "195px" }} />}</a>  
       <div className="fillContent">
          <p style={{color:'black' ,fontWeight: "bolder", fontSize:'1rem' }}>{d.category}</p>
        </div>

        <a style={{ color: "black", textDecoration:'none' }} href={d.link} target="_blank">
        <div
          className="truncate"
          style={{ color: "#9fa1a3", fontWeight: "bolder", fontSize:'1.2rem' }}
        >
          {d.title}{" "}
        </div></a>

        <div
          className="truncatedescription"
          dangerouslySetInnerHTML={{ __html: d.description }}
        />
        <a className="btn btn-secondary" style={{ color: "white", textDecoration:'none' }} href={d.link} target="_blank">
            Read More
          </a>
      </div>
    ));

    if (isLoading) {
      return (
        <div className="textChange">
          <h3 style={{ marginLeft: 4 }}>News</h3>
          <div className="row">{data}</div>
          <a 
          className="btn btn-secondary" style={{marginBottom:'50px'}} onClick={this.handleClick}>
            {!this.state.expanded ? (
              <span style={{ color: "white" }}>Show More</span>
            ) : (
              <span style={{ color: "white" }}> Show Less</span>
            )}
          </a>
        </div>
      );
    } else {
      return <Loader1/>
    }
  }
}
export default News;
