import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
// import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Details from "./details";
import VideoPlayer from "./videoPlayer";
// import Axios from "axios";
import Comments from "./comments";
import ChannelInfo from "./channelInfo";
import VideoList from "../videos/videoList";
import "../../styles/details.scss";
import { getVideoById } from "../../connectivity/player/api.videoById";

class PlayerLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoResponse: "",
      videoDetails: "",
      id: ""
    };
    // console.log(this.props);
  }

  componentDidMount() {
    getVideoById(this.props.player).then(res => {
      // console.log("*-*-* HEEEYYYY *-*-*-*-*", res.data.data);
      this.setState({ videoResponse: res.data.data.video });
      this.setState({ videoDetails: res.data.data });
    });
  }

  render() {
    return (
      <Grid container className="main-container">
        <Grid item md={8} sm={12} xs={12} zeroMinWidth>
          <VideoPlayer video={this.state.videoResponse} />
          <Grid container spacing={3} className="channelDescription">
            <Grid item lg={8} xs={12} className="channelTitle" zeroMinWidth>
              <Typography noWrap component={"span"}>
                <Details details={this.state.videoDetails} />
              </Typography>
            </Grid>

            <Grid item lg={4} xs={12} className="channelProfile" zeroMinWidth>
              {
              // console.log(
              //   "the props we sent from playerLayout.jsx",
              //   this.state.videoDetails
              // )
              }
              <ChannelInfo channelDetails={this.state.videoDetails.channel} />
            </Grid>
          </Grid>

          <Comments
            comments={this.state.videoDetails.comments}
            playerId={this.props.player}
            className="comments"
          />
        </Grid>

        <Grid item md={4} sm={12} xs={12} className="videoList">
          <VideoList categoryId={this.props} />
        </Grid>
        {/* <Grid item md={8} sm={12} xs={12}>
            
          </Grid> */}
      </Grid>
    );
  }
}

export default PlayerLayout;
