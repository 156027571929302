import React, {Component} from "react";

class EsewaFailure extends Component {
    render() {
       return (
           <React.Fragment>
               <h1>Failure</h1>
           </React.Fragment>
       ) 
    }
}

export default EsewaFailure;