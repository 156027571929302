import React, { Component } from 'react';
import Axios from 'axios';
import Loader1 from '../loader/loader';
import Grid from "@material-ui/core/Grid";
class TopCharts extends Component {
    constructor(props){
        super(props);
        this.state = {
            userId:1,
            isLOading:false,
            data:[]
        }
    }

    getData = () =>{

        const {userId} = this.state;
        Axios.get(`https://mnm.nettvnepal.com.np/api/net-tv/audios/popular?page=${userId}`)
        .then(res =>{
            if(!this.Unmounted){
                this.setState({
                    data:res.data.items,
                    isLoading:true
                })
            }
        })
        .catch (e =>{
            console.error(e)
        })
        
    }

    btnClick = (e) =>{
        const userId = e.target.value
        this.setState({
            userId
        })
        this.getData()
    }

    componentDidMount(){
        this.getData()
    }

    render() {
        const{data, isLoading} = this.state;
        // console.log(data)
        const theData = data.map((d) =>
        <div className="col-md-3 col-sm-4 col-xs-4 py-3 px-3">
        <div className="geetInfo1">
                    <img alt="icon" src={d.logo} className="img-fluid" />
                    <div className="truncate cardContent" style={{textAlign:'center'}}><p >{d.title}</p></div>
                    </div>
                    </div>
        )
        if(isLoading){
        return (
            <div>
            <div className="row">  
            {theData}
            </div>
                <div className=" ml-5 "style={{textAlign:"center", marginBottom:'60px'}}>
                    <UserIdComponent name = "1" onClick={this.btnClick}/>
                    <UserIdComponent name = "2" onClick={this.btnClick}/>
                    <UserIdComponent name = "3" onClick={this.btnClick}/>
                </div>
        
            </div>
        )}
        else return <Loader1/>
    }
}
const UserIdComponent = (props) =>{
    return(
        <button className="the-btn" value= {props.name} onClick={props.onClick}>{props.name}</button>
    )
}

export default TopCharts;
