import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import OneVideoInList from "./oneVideoList";
// import Axios from "axios";
// import { Route, Switch, Link, Redirect } from "react-router-dom";
import "../../styles/videos.css";
import { GetCategoryList } from "../../connectivity/player/api.getCategoryList";

class VideoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryId: ""
    };
    // console.log("VideoProps", this.props);
  }

  componentDidMount() {
    GetCategoryList(localStorage.getItem("ClickedCategoryVideoID")).then(res =>
      this.setState({ categories: res.data.data })
    );
  }

  render() {
    return (
      <div>
        {/* {console.log("MAPPING", this.state.categories)} */}
        <Typography variant="h4" className="upNext">
          Up Next
        </Typography>
        {this.state.categories.map(category => (
          // <Link to="/player">
          <OneVideoInList key={category.id} category={category} />
          // </Link>
        ))}
      </div>
    );
  }
}

export const getCategoryId = id => {};

export default VideoList;
