import React, { useState, useEffect } from "react";
// import AddComments, { addCommentState } from "./addComments";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../../styles/details.scss";
// import UserInfo from "../userInfo";
import AddCommentContainer from "../../containers/player/addCommentContainer";

// console.log(addCommentState);

const Comments = props => {
  const [comments, setComments] = useState(null);
  // console.log(props);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await props.comments;
      if (mounted) {
        setComments(response);
      }
    };
    loadData();

    return () => {
      mounted = false;
    };
  }, [props]);

  if (!comments) {
    return <span>loading comments...</span>;
  }

  return (
    <div className="comments">
      <Typography variant="h6">Comments</Typography>
      {/* <AddComments style={{ marginBottom: "1rem" }} /> */}
      {/* {console.log(comments)} */}

      {/* <UserInfo /> */}
      <AddCommentContainer player={props.playerId} />

      {comments.data
        .map(comment => (
          <div className="commentSection" key={comment.id} id={comment.id}>
            <div className="userImage">
              <img
                src={require("../../assets/dummyChannelLogo.jpg")}
                alt="User"
              />
            </div>
            <div className="comments">
              <Typography className="commenter">
                {comment.user.first_name}
              </Typography>
              <Typography className="commentBody">{comment.body}</Typography>
              <div className="subCommentSection">
                <ExpansionPanel className="replyComment">
                  <ExpansionPanelSummary
                    aria-controls="add-reply"
                    id="addreply"
                  >
                    <Typography component={"span"}>Reply</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography component={"span"}>
                      <AddCommentContainer
                        commentId={comment.id}
                        player={props.playerId}
                      />
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  className="commentReplies"
                  hidden={comment.childs.length === 0}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="replies"
                    id="view-replies"
                  >
                    <Typography>Veiw Replies</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography component={"span"}>
                      {comment.childs
                        .map(subcomments => (
                          <div
                            className="subComments"
                            key={subcomments.id}
                            id={subcomments.id}
                          >
                            <div className="subUserImage">
                              <img
                                src={require("../../assets/dummyChannelLogo.jpg")}
                                alt="user"
                              />
                            </div>
                            <div className="comments">
                              <div className="commenter">
                                {subcomments.user.first_name}
                              </div>
                              <div className="commentBody">
                                {subcomments.body}
                              </div>
                            </div>
                          </div>
                        ))
                        .reverse()}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>
          </div>
        ))
        .reverse()}
    </div>
  );
};

export default Comments;
