import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/common.css";
import "../../styles/videos.css";
import Grid from "@material-ui/core/Grid";
import Loader1 from "../loader/loader";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
import Axios from 'axios';
// import { CategoriesVideosList } from "../../connectivity/home/api.home";
//import { getCategoryId } from "./videoList";
// import { ViewCount } from "../../connectivity/home/api.increaseViewCount";

class DetailHome extends Component {
  constructor(props){
    super(props);
    this.state= {
      track:[],
      isLoading:false,
      
    }
  }

  fetchDetail = () =>{
    const { data } = this.props.location.state;
    // console.log(data);
    // console.log(data.slug)
    if(localStorage.getItem("helloToken1") || localStorage.getItem("helloToken")){
    Axios ({
      url: "https://mnm.nettvnepal.com.np/api/net-tv/audios/details?type="+data.type+"&slug="+data.slug,
      method: "GET",
      headers: {
          Authorization: (localStorage.getItem("helloToken1")||localStorage.getItem("helloToken"))
      },
    }).then((result)=>{
      let responseJson = result;
      // console.log("result from playlist:", responseJson);

      if(responseJson){
            this.setState({
              track:responseJson.data.data.tracks,
              statusCheck:true,
              isLoading:true
              
            })}
      },
      err => {
          this.setState({
              errorMessage: err
          })
      })
    }
    
  }


  
  handleClick = () =>{
    this.setState({
      isShow:true
    })
    
    
  }
  handleShow = () => {
    this.setState({
        show:true
    })
  }
  handleClose = () =>{
      this.setState({
        show:false
      })
  }

  componentDidMount = () =>{
    this.fetchDetail()

  }

  componentWillUnmount = () => {
   this.Unmounted = true
}

  render() {
      // console.log("track", this.state.track)
      
    // Modal Starts
    if(this.state.show){
      return (
        <div className="modal ">
        <Modal
         show={this.state.show} onHide={()=>this.handleClose()}
         centered
         
        >
        <Modal.Header closeButton >
        <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
        <div className = "row">
        <div className="col-4">
        </div>
        <div className="col-4">
        <div className = "row">
        <div className="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
        <img src = {playstore} /> 
        </a>
        </div>
        <div className="col-6">
        <img src = {appstore}/>
        </div>
        </div>
        </div>
        <div className="col-4">
        </div>
        </div>
        <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
        <p style={{textAlign:'center'}}>Scan QR Code</p>
        <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
        <div className="row">
        <div className="col-2  "><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
        </div>
        <div className="col-2">
        </div>
        <div className="col-2">
        </div>
        </div>
        </div>
        <div className="col-4"></div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={()=>this.handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    )
    }
    //Modal Ends
    if((localStorage.getItem('helloToken1') || localStorage.getItem('helloToken'))){
    return (
      <React.Fragment>
      <Grid container spacing={4} className="homepageGridContainer">
      <div className="row">
                    {this.state.track.map(e =>
                      <div className="col-6 col-md-2">
                      <div className="videoInfo1 " style={{marginBottom:25}}>
                      <img onClick={ () => this.handleShow()} src = {e.logo}/> 
                      <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
                      {e.title}
                      </div>
                      <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400', color:'#e93e22', textTransform:'capitalize' }} >
                      {e.type}
                      </div>
                      </div>
                      </div>
                      )
                    }  
                  </div> 
      </Grid>
      
             
       </React.Fragment>
    )}else{
      return (
        <Grid container spacing={4} className="homepageGridContainer">
          <h3>Please Login To View Playlists/Albums. </h3>
        </Grid>
        )
    }}
    
  
}

export default DetailHome;