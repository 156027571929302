import React, { Component } from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LocalDining } from '@material-ui/icons';
import Loader1 from '../loader/loader';

class About extends Component {
    constructor(props){
        super(props)
        this.state={
            isLoaded:false
        }
    }
fetchPosts =()=> {
     axios.get("https://mnm.nettvnepal.com.np/api/net-tv/setting/aboutus")
        .then(res =>{
            
            if(!this.unMounted){
                this.setState({
                    posts:res.data,
                    isLoaded:true
            })
        }
    })
    .catch((error) =>{
        // console.log(error);
    }) 
}  

componentDidMount =()=>{
        this.fetchPosts()
    
}

componentWillUnmount(){
    this.Unmounted = true
}

    render() {
        const {posts, isLoaded} = this.state
        //console.log(posts)
        if(isLoaded){
        return (
            <div>
            <div dangerouslySetInnerHTML={{__html: posts}} />
            </div>
        )
        }
        else{
            return <Loader1/>
        }
    }
}
export default About