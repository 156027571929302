import React, { Component } from "react";
import axios from 'axios';
import "../../styles/common.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/videos.css";
import Loader1 from "../loader/loader";
import {Image, Dimensions} from 'react-native';

// import { CategoriesVideosList } from "../../connectivity/home/api.home";
//import { getCategoryId } from "./videoList";
// import { ViewCount } from "../../connectivity/home/api.increaseViewCount";

class tvShows extends React.Component {
  constructor(props){
    super(props);
    this.state= {
      categories:[],
      isLoading:false,
      
    }
  }

  fetchCategories =()=>{
    axios.get("https://tvshows.newitventure.com/api/v1/tvshows/nettvgo/all")
    .then(res => { if(!this.unMounted){
                     this.setState({
                         categories:res.data.data.categories,
                         isLoading:true
                 })
             }
                     })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }

  componentDidMount = () =>{
    this.fetchCategories()

  }

  componentWillUnmount = () => {
   this.Unmounted = true
}

  render() {
    
    const{categories, isLoading} = this.state;
    // console.log("object", categories)
    {/**Channels */}
    const getCategories = categories.map(d =>
      <div>
      <h3 className="textChange text-capitalize py-3 px-3">{d.name}</h3>
            <div className="row textChange">
            {d.tvshows.map(a=>
              <div className="textChange col-md-2 col-sm-3 col-xs-6 ">
              <div className="py-3 px-3">
              <Image
                                style={{height:Dimensions.get('window').height/8, width:'100%'}}
                                source={
                                a.logo
                                }resizeMode="stretch"/>
              <p className="truncate" style={{textAlign:'center'}}>{a.name}</p></div>
              </div> 
              )}
              </div>
              <hr/>
          </div>
          )

    if(isLoading){
    return (
      <React.Fragment>
              <div>
              {/**Categories */}
              {getCategories}
              </div>
             
       </React.Fragment>
    );
    }
    else return <Loader1/>
  }
}

export default tvShows;
