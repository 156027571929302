import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import "./contactUs.scss";
import { PostContact } from "../../connectivity/apiContact";
import Map from '../map/map';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  
  const formValid = ({ formErrors, ...rest }) => {
    let valid = true;
  
    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
      val.length > 0 && (valid = false);
    });
  
    // validate the form was filled out
    Object.values(rest).forEach(val => {
      val === null && (valid = false);
    });
  
    return valid;
  };
class ContactUs extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          email: null,
          name:null,
          phone:null,
          subject:null,
          message:null,
          statusCheck:false,
          formErrors: {
            email: "",
            name: "",
            phone: "",
            subject: "",
            message: "",
          },
        };
        
      }

//         handleChange = (e) =>{
    
//     this.setState({
//         [e.target.name]:e.target.value
//     })
// }

      handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };
      
         switch (name) {
          
          case "email":
            formErrors.email = emailRegex.test(value)
              ? ""
              : "invalid email address";
            break;
          case "name":
            formErrors.name =
              value.length === null ? " Required" : "";
            break;
            case "phone":
            formErrors.phone =
              value.length === null ? " Required" : "";
            break;
            case "subject":
            formErrors.subject =
              value.length === null ? " Required" : "";
            break;
            case "message":
            formErrors.message =
              value.length === null ? " Required" : "";
            break;
          default:
            break;
        }
      
        this.setState({ formErrors, [name]: value }, () => console.log(this.state));
      };

      handleContactSubmit = (e) =>{
        e.preventDefault();
        if(this.state.name && this.state.email && this.state.phone && this.state.subject && this.state.message){
            // console.log("data sent for Contact:", this.state);
            PostContact('contact-us', this.state).then((result)=>{
            let responseJson = result;
            // console.log("result from Contact api:", responseJson);
            if(responseJson){
                  this.setState({
                    email: "",
                    name:"",
                    phone:"",
                    subject:"",
                    message:"",
                    formErrors: {
                      email: "",
                      name: "",
                      phone: "",
                      subject: "",
                      message: "",
                    },
                    statusCheck:true,
                    responseSuccess: responseJson.message,
                    status:responseJson.status
                  });
                  
                  }})
        .catch(error =>{
          this.setState({
            alert_message:'error'
          })
        })
      }
      }
    //   componentDidMount = () =>{
    //     this.handleContactSubmit();
    //   }

    render() {
        // console.log("object", this.state.responseSuccess)

        const { formErrors } = this.state;
        return (
            <React.Fragment>
            
                <div className="gridContainerContact">
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <div className="circle">
                                <Typography variant="h6" gutterBottom>
                                    Customer HelpDesk
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Phone: 9851201614
                                </Typography>
                              {/**  <Typography variant="body2" gutterBottom>
                                    Email: momo@momo.com
                                </Typography>  */}
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p style={{backgroundColor:'#e93e22',color:'white', textAlign:'center' }}>
                                    {this.state.responseSuccess} 
                                    </p>
                            
                                <Typography variant="h6" gutterBottom>
                                    Contact Us 
                                </Typography>
                                <form
                                onSubmit={this.handleContactSubmit}
                                id="signUpForm"
                                name= "contactForm"
                                required
                                >
                                <TextField 
                                    id="outlined-basic" 
                                    label="Your Name" 
                                    value = {this.state.name}
                                    className={formErrors.name.length > 0 ? "error" : "fields"}
                                    className="fields"
                                    margin="normal"
                                    name="name"
                                    type="name"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    required />
                                <TextField 
                                    id="outlined-basic" 
                                    label="Email" 
                                    value = {this.state.email}
                                    className={formErrors.email.length > 0 ? "error" : "fields"}
                                    className="fields"
                                    margin="normal"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    required
                                    />
                                <TextField 
                                    id="outlined-basic" 
                                    label="Phone Number" 
                                    value = {this.state.phone}
                                    className={formErrors.phone.length > 0 ? "error" : "fields"}
                                    className="fields"
                                    margin="normal"
                                    name="phone"
                                    type="phone"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    required 
                                    />
                                <TextField 
                                    id="outlined-basic" 
                                    label="Subject" 
                                    value = {this.state.subject}
                                    className={formErrors.subject.length > 0 ? "error" : "fields"}
                                    className="fields"
                                    margin="normal"
                                    name="subject"
                                    type="subject"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    required 
                                    />
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Your Message"
                                    multiline
                                    rows={4}
            
                                    // defaultValue="Default Value"
                                    variant="outlined"
                                    value = {this.state.message}
                                    className={formErrors.message.length > 0 ? "error" : "fields"}
                                    className="fields"
                                    margin="normal"
                                    name="message"
                                    type="message"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    required
                                />
                                <Button variant="contained" 
                                className="momoOrangeBtn"
                                type = "submit">Submit</Button>
                                
                            </form>
                        </Grid>
                    </Grid>
                    <div style = {{ height: 80 }}></div>
                    <Grid container spacing={3}>
                      {/**  <Grid item sm={4} xs={12}>
                            <img src = {require("../../assets/demoMap.png")} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <img src = {require("../../assets/demoMap.png")} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                        <Map/>
                        </Grid> */}
                        <Map/>
                    </Grid>
                </div>

            </React.Fragment>
        )
    }
}

export default ContactUs;