import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import Axios from "axios";
import "../../styles/login.scss";
import RegisterNTC from "./registerNTC";
// import { SignUp } from "../../connectivity/api.authentication";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = formErrors => {
  let valid = true;

  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class NormalSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: false,
      first_name: null,
      last_name: null,
      email: null,
      //mobile: null,
      password: null,
      password_confirmation: null,
      code:null,
      showToast: false,
      phone_signup:false,
      signUpSubmit:false,
      formErrors: {
        first_name: "",
        last_name: "",
        email: "",
        //mobile: "",
        password: "",
        code:"",
        password_confirmation: ""
      }
    };
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    // console.log("Name: ", name);
    // console.log("value: ", value);

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      // case "mobile":
      //   formErrors.mobile.value.length < 10 ||
      //   formErrors.mobile.value.length > 10
      //     ? "Invalid Mobile Number"
      //     : "";
      //   break;
      case "password_confirmation":
        // console.log("form conform", value === this.state.password);
        formErrors.password_confirmation =
          value === this.state.password ? "" : "Password does not match";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimun 6 characters required" : "";
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  };

  handleSubmit = e =>{
    e.preventDefault();
    this.setState({
      phone_signup:!this.state.phone_signup
    })

  }

  handleSignUpSubmit = e => {
    e.preventDefault();
    if (!this.state.accept) {
      toast("Please Accept to the terms and condition");
      return;
    }
    if (this.state.formErrors.password_confirmation.length > 0) {
      toast("Password did not match");
      return;
    }
    // console.log("sign up");
    // console.log(`
    //    --SUBMITTING--
    //     First Name: ${this.state.first_name}
    //     Last Name: ${this.state.last_name}
    //     Email: ${this.state.email}
    //     Password: ${this.state.password}
    //    `);

    let formdata = new FormData();
    formdata.append("first_name", this.state.first_name);
    formdata.append("last_name", this.state.last_name);
    formdata.append("email", this.state.email);
    //formdata.append("mobile", this.state.mobile);
    formdata.append("password", this.state.password);
    formdata.append("code", this.state.code);

    formdata.append("password_confirmation", this.state.password_confirmation);

    // console.log(Array.from(formdata));

    if (
      this.state.formErrors.email.length > 0 ||
      this.state.formErrors.password.length > 0 ||
      this.state.password_confirmation !== this.state.password
    ) {
      // console.log("error");
      toast("Sign up Error");
    } else {
      // return SignUp(
      //   this.state.firstName,
      //   this.state.lastName,
      //   this.state.email,
      //   this.state.mobile,
      //   this.state.password
      // );
      return Axios({
      
        url: 'https://mnm.nettvnepal.com.np/api/net-tv/register',
        method: "POST",
        data: formdata
      }).then((result)=>{
        let responseJson = result;
        // console.log("result from Phone api:", responseJson);
        if(responseJson){
              this.setState({
                statusCheck:true,
                responseSuccess: responseJson.data.token,
                responseStatus:responseJson.status,
                responseText:responseJson.statusText
              })
            }})
            .catch((error) => {
              this.setState({
                error: error.response.data.message
              })
              // console.log(error.response.data.message)
          });
    }
  };

  handleSignUp = () =>{
    this.setState({
      signUpSubmit:true

    })
  }

  accept = () => {
    this.setState({
      accept: !this.state.accept
    });
  };

  render() {
    const { formErrors } = this.state;
    // console.log("accept is", this.state.accept);
    // if(this.state.phone_signup){
    //   return <RegisterNTC/>
    // }
    return (
      <React.Fragment>
      {this.state.signUpSubmit? 
        (this.state.responseStatus === 200 && this.state.responseText === "OK")? 
        <p style={{backgroundColor:'#e93e22', }}>Sucessfully Sign Up</p> : 
        (this.state.responseStatus !== 200 && this.state.responseText !== "OK")?<p style={{backgroundColor:'#e93e22', }}>Sign Up Fail</p>:
        "":
        <p style={{backgroundColor:'#e93e22', }}>{localStorage.getItem("message")}</p>}
       
      
        <form
          onSubmit={this.handleSignUpSubmit}
          id="signUpForm"
          required
          autoComplete="off"
        >
          <Typography variant="h4">Enter Verification Code</Typography>
          {/* <TextField
            id="outlined-with-placeholder"
            label="First Name"
            placeholder="First Name"
            className={
              formErrors.first_name.length > 0 ? "error" : "fields"
            }
            className="fields"
            margin="normal"
            name="first_name"
            type="first_name"
            variant="outlined"
            onChange={this.handleChange}
            required
          /> */}
          {/* <TextField
            id="outlined-with-placeholder"
            label="Last Name"
            className="fields"
            margin="normal"
            name="last_name"
            type="last_name"
            variant="outlined"
            onChange={this.handleChange}
            required
          /> */}
          {/* <TextField
            id="outlined-with-placeholder"
            label="Email"
             placeholder="Email or Phone Number"
            className={formErrors.email.length > 0 ? "error" : "fields"}
             className="fields"
            margin="normal"
            name="email"
            type="email"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {formErrors.email.length > 0 && (
            <Typography variant="caption" className="errorMessage">
              {formErrors.email}
            </Typography>
          )} */}
          <TextField
            id="outlined-with-placeholder"
            label="Code"
            className="fields"
            margin="normal"
            name="code"
            type="code"
            variant="outlined"
            onChange={this.handleChange}
            required
          />

          {/* <TextField
            id="outlined-with-placeholder"
            label="Password"
             placeholder="Password"
            className={formErrors.password.length > 0 ? "error" : "fields"}
            className="fields"
            type="password"
            autoComplete="current-password"
            margin="normal"
            name="password"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {formErrors.password.length > 0 && (
            <Typography variant="caption" className="errorMessage">
              {formErrors.password}
            </Typography>
          )} */}
          {/* <TextField
            id="outlined-with-placeholder"
            label="Confirm Password"
            placeholder="Password"
            className={
              formErrors.password_confirmation.length > 0 ? "error" : "fields"
            }
            className="fields"
            type="password"
            autoComplete="current-password"
            margin="normal"
            name="password_confirmation"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
         {formErrors.password_confirmation && (
          <Typography variant="caption" className="errorMessage">
            {formErrors.password_confirmation}
          </Typography>
        )} 
          {formErrors.password_confirmation.length > 0 ? (
            <Typography variant="caption" className="errorMessage">
              Password Does Not Match
            </Typography>
          ) : (
            ""
          )} */}
          {/* <div className="accept-terms">
            <Checkbox
              value="checkedC"
              inputProps={{
                "aria-label": "uncontrolled-checkbox"
              }}
              className={this.state.accept ? "ticked" : ""}
              onClick={this.accept}
            />
            <Typography variant="caption">
              I Accept The Terms and Conditions
            </Typography>
          </div> */}
          <div className="login">
            <Button
              variant="contained"
              className="momoOrangeBtn"
              type="submit"
              onClick={this.handleSignUp}
            >
              Submit
            </Button>
            
          </div>
        </form>
        {/** <Button
              variant="contained"
              color="secondary"
              className="loginBtn"
              type="submit"
              //onClick={this.handleSubmit}
              
            >
              Sign Up With Phone
            </Button>  */}
      </React.Fragment>
    );
  }
}

export default NormalSignUp;
