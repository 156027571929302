import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import Axios from "axios";
import "../../styles/login.scss";
import RegisterNTC from "./registerNTC";
// import { SignUp } from "../../connectivity/api.authentication";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = formErrors => {
  let valid = true;

  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class CodeVerify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: false,
      phone: null,
      code: null,
      password: null,
      password_confirmation: null,
      showToast: false,
      formErrors: {
        phone: "",
        password: "",
        code:"",
        password_confirmation: ""
      }
    };
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    // console.log("Name: ", name);
    // console.log("value: ", value);

    switch (name) {
      
      case "password_confirmation":
        // console.log("form conform", value === this.state.password);
        formErrors.password_confirmation =
          value === this.state.password ? "" : "Password does not match";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimun 6 characters required" : "";
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  };

  handleSignUpSubmit = e => {
    e.preventDefault();
    if (!this.state.accept) {
      toast("Please Accept to the terms and condition");
      return;
    }
    if (this.state.formErrors.password_confirmation.length > 0) {
      toast("Password did not match");
      return;
    }
    // console.log("sign up");
    let formdata = new FormData();
    formdata.append("phone", this.state.phone);
    formdata.append("password", this.state.password);
    formdata.append("code", this.state.code);
    formdata.append("password_confirmation", this.state.password_confirmation);

    // console.log(Array.from(formdata));

    if (
      this.state.formErrors.password.length > 0 ||
      this.state.password_confirmation !== this.state.password
    ) {
      // console.log("error");
      toast("Sign up Error");
    } else {
      // return SignUp(
      //   this.state.firstName,
      //   this.state.lastName,
      //   this.state.email,
      //   this.state.mobile,
      //   this.state.password
      // );

      return Axios({
        url: 'https://mnm.nettvnepal.com.np/api/net-tv/otp/verify/ntc',
        method: "POST",
        // headers: {
        //   authorization: ""
        // },
        data: formdata
      }).then((result)=>{
        let responseJson = result;
        // console.log("result from api:", responseJson);
        //console.log("status",this.state.status )
        if(responseJson.userData){
            sessionStorage.setItem('userData', JSON.stringify(responseJson) );
        }
        if(responseJson){
              this.setState({
                statusCheck:true,
                responseSuccess: responseJson.data['hash-code'],
                responseError:responseJson.data.error
              })
            }})
    }
  };

  handleRegisterSubmit = e => {
    e.preventDefault();
    if (!this.state.accept) {
      toast("Please Accept to the terms and condition");
      return;
    }
    if (this.state.formErrors.password_confirmation.length > 0) {
      toast("Password did not match");
      return;
    }
    // console.log("sign up");
    let formdata = new FormData();
    formdata.append("phone", this.state.phone);
    formdata.append("password", this.state.password);
    formdata.append("code", this.state.code);
    formdata.append("password_confirmation", this.state.password_confirmation);

    // console.log(Array.from(formdata));

    if (
      this.state.formErrors.password.length > 0 ||
      this.state.password_confirmation !== this.state.password
    ) {
      // console.log("error");
      toast("Sign up Error");
    } else {
      // return SignUp(
      //   this.state.firstName,
      //   this.state.lastName,
      //   this.state.email,
      //   this.state.mobile,
      //   this.state.password
      // );
      return Axios({
        url: 'https://mnm.nettvnepal.com.np/api/net-tv/register/ntc',
        method: "POST",
        headers: {
            'Accept':'application/json',
            'Content-type':'application/json',
            'hash-code':this.state.responseSuccess
           // authorization: ""
        },
        data: formdata
      }).then((result)=>{
        let responseJson = result;
        // console.log("result from api:", responseJson);
        if(responseJson){
              this.setState({
                statusCheck:true,
                responseSuccess: responseJson.status,
              })
            }
        })
    }
  };


  accept = () => {
    this.setState({
      accept: true
    });
  };

  render() {
    const { formErrors } = this.state;
    // console.log("accept is", this.state.accept);
    if(this.state.responseSuccess){
        return (
            <React.Fragment>
            {this.state.responseSuccess}
              <form
                onSubmit={this.handleRegisterSubmit}
                id="signUpForm"
                required
                autoComplete="off"
              >
                <Typography variant="h4">SIGN UP</Typography>
                <TextField
            id="outlined-with-placeholder"
            label="Password"
             placeholder="Password"
            className={formErrors.password.length > 0 ? "error" : "fields"}
            className="fields"
            type="password"
            autoComplete="current-password"
            margin="normal"
            name="password"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
          {formErrors.password.length > 0 && (
            <Typography variant="caption" className="errorMessage">
              {formErrors.password}
            </Typography>
          )}
          <TextField
            id="outlined-with-placeholder"
            label="Confirm Password"
            placeholder="Password"
            className={
              formErrors.password_confirmation.length > 0 ? "error" : "fields"
            }
            className="fields"
            type="password"
            autoComplete="current-password"
            margin="normal"
            name="password_confirmation"
            variant="outlined"
            onChange={this.handleChange}
            required
          />
         {formErrors.password_confirmation && (
          <Typography variant="caption" className="errorMessage">
            {formErrors.password_confirmation}
          </Typography>
        )} 
          {formErrors.password_confirmation.length > 0 ? (
            <Typography variant="caption" className="errorMessage">
              Password Does Not Match
            </Typography>
          ) : (
            ""
          )}
                <div className="accept-terms">
                  <Checkbox
                    value="checkedC"
                    inputProps={{
                      "aria-label": "uncontrolled-checkbox"
                    }}
                    className={this.state.accept ? "ticked" : ""}
                    onClick={this.accept}
                  />
                  <Typography variant="caption">
                    I Accept The Terms and Conditions
                  </Typography>
                </div>
                <div className="login">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="loginBtn"
                    type="submit"
      
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </React.Fragment>
      
        )}
    return (
      <React.Fragment>
      
        <form
          onSubmit={this.handleSignUpSubmit}
          id="signUpForm"
          required
          autoComplete="off"
        >
          <Typography variant="h4">SIGN UP</Typography>
          
          <TextField
            id="outlined-with-placeholder"
            label="Mobile Number"
            // placeholder="Email or Phone Number"
            //  className={formErrors.mobile.length > 0 ? "error" : "fields"}
            className="fields"
            margin="normal"
            name="phone"
            type="phone"
            variant="outlined"
            onChange={this.handleChange}
            required
          /> 
          <TextField
            id="outlined-with-placeholder"
            label="Code"
            // placeholder="Email or Phone Number"
            //  className={formErrors.mobile.length > 0 ? "error" : "fields"}
            className="fields"
            margin="normal"
            name="code"
            type="code"
            variant="outlined"
            onChange={this.handleChange}
            required
          /> 
         
          <div className="accept-terms">
            <Checkbox
              value="checkedC"
              inputProps={{
                "aria-label": "uncontrolled-checkbox"
              }}
              className={this.state.accept ? "ticked" : ""}
              onClick={this.accept}
            />
            <Typography variant="caption">
              I Accept The Terms and Conditions
            </Typography>
          </div>
          <div className="login">
            <Button
              variant="contained"
              color="secondary"
              className="loginBtn"
              type="submit"

            >
              Submit
            </Button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default CodeVerify;
