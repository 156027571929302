import { getBaseRequestConfig } from "./baseRequestConfig";
import asyncFetch from "./async-fetch";

export async function getUserInfo() {
  const url = process.env.REACT_APP_API_URL + "/user";
  // console.log(process.env.REACT_APP_API_URL);
  // debugger;
  const baseRequestConfig = getBaseRequestConfig();

  const response = await asyncFetch(url, baseRequestConfig);

  return await response;
}
