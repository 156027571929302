// import { toast } from "react-toastify";

export default class ApiCallError extends Error {
  constructor(message, statusCode) {
    super(message);

    this.message = message;
    this.statusCode = statusCode;
    // console.log("ERROR!!", this.message);
    // toast.error(this.message);
    // this.stack = new Error(message).stack;
    this.name = "ApiCallError";
  }
}
