import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loader1 from '../loader/loader';
//import {View} from 'react-native';
import Grid from "@material-ui/core/Grid";
class FeaturedAPagination extends Component{
    constructor() {
        super();
        this.state={
          cur: 1,
          total: 1269,
          data1:[],
          isloading:false
        }
      }
      componentDidMount(){
        this.LoadData();
      }
    
      componentWillUnmount(){
          this.Unmounted=true
      }
       
    
      paginate = (page) => {
        if(!this.Unmounted){
            this.setState({ page: page });
        }
            this.LoadData(page)
    }
    
      LoadData =(page) =>{
        // console.log("LoadData",page)
        if(!this.Unmounted){
        if(page){
            axios.get("https://mnm.nettvnepal.com.np/api/net-tv/audios/artists/featured?page="+page)
            .then(json => {
                //console.log(json.data)
                this.setState({
                    data1:json.data.items,
                    isloading:true
                })
                
                })
                .catch(e => {
                  console.error(e)
                  this.setState({
                      data1:[],
                      isloading:false
                  })
          })
              }else{
              axios.get("https://mnm.nettvnepal.com.np/api/net-tv/audios/artists/featured?page="+1)
              .then(json => {
                  // console.log("json Data",json.data.items)
                  this.setState({
                      data1:json.data.items,
                      isloading:true
                  })
                  
                  })
    
                .catch(e => {
                  console.error(e)
                  this.setState({
                      data1:[],
                      isloading:false
                  })
          })}} 
        } 
    render(){
        let {cur, total, data1, isloading} = this.state;
        const theData = this.state.data1.map((d) =>
        <div className="col-md-3 col-lg-3 col-6 py-3 px-3">
        <div className="geetInfo1 link">
                    <img alt="icon" src={d.logo} className="img-fluid" />
                    <div className="truncate cardContent" style={{textAlign:'center'}}><p >{d.title}</p></div>
                    </div>
                    </div>
        )
        if(isloading){
        return(
            <div>
        
            {/*Explanation Starts*/}
            <Grid container spacing={4} className="homepageGridContainer">
            <div className="row">  
            {theData}
            </div>
            </Grid>
        <div className=" ml-5  "style={{textAlign:"center", marginBottom:'60px'}}>
        <button onClick={()=>{
            if(this.state.cur===1)
            return
            this.setState({cur:cur-1})
            // console.log(cur)
            this.LoadData(cur)
  
          
          }}>Previous</button>
          <button onClick={()=>this.paginate(1)} >1</button>
          <button onClick={()=>this.paginate(2)}>2</button>
      
          {cur <=2 ? null : <span>...</span>}
          {((cur === 1) ||((cur===2))|| (cur === total)) ? null : <button onClick={()=>this.paginate(cur)}>{cur}</button>}
          {cur >=99 ? null : <span>...</span>}
          <button onClick={()=>this.paginate(total)}>{total}</button>
          <button onClick={()=>{
              if(cur===total)
                return
              cur++;
              this.setState({cur})
              // console.log(cur)
              this.LoadData(cur)
            }}>Next</button>
            
        </div>
            {/*EXPLANATION END*/}
            </div>
        )}else return <Loader1/>
    }
}
export default FeaturedAPagination
