//type can be any register, login, field
export function PostContact(type, userData){
    // console.log("object", userData)
let BaseUrl = "https://mnm.nettvnepal.com.np/api/net-tv/"
// console.log("data", userData.email)
let formdata = new FormData();
    formdata.append("name", userData.name);
    formdata.append("email", userData.email);
    formdata.append("phone", userData.phone);
    formdata.append("subject", userData.subject);
    formdata.append("message", userData.message);
return new Promise((resolve,reject) => {
    fetch(BaseUrl + type,{
    method: "POST",
    // headers : {
    //     'Content-Type' : 'application/json',
    //     'Accept' : 'application/json',
    // },
    body: formdata
    })
    .then((response) => 
    response.json())
    .then((res) =>{
        resolve(res)
    })
    .catch((error) => {
        reject(error);
    });
});



    }