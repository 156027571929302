import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/common.css";
import "../../styles/videos.css";
import Grid from "@material-ui/core/Grid";
import ReactStars from "react-rating-stars-component";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
import play from '../../assets/play.png';
import "../../styles/detailMovie.css";

class DetailMovie extends Component {
  constructor(props){
    super(props);
    this.state= {
      show:false,
      isShow:false
      
    }
  }
  handleClick = () =>{
    this.setState({
      isShow:true
    })
}

  handleShow = () => {
    this.setState({
        show:true
    })
  }

  handleClose = () =>{
      this.setState({
        show:false
      })
  }

  render() {
      const { data } = this.props.location.state;
      // console.log(data.redirect_link);
    // Modal Starts
    if(this.state.show){
      return (
        <div className="modal ">
        <Modal
         show={this.state.show} onHide={()=>this.handleClose()}
         centered
         
        >
        <Modal.Header closeButton >
        <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
        <div className = "row">
        <div className="col-4">
        </div>
        <div className="col-4">
        <div className = "row">
        <div className="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
        <img src = {playstore} /> 
        </a>
        </div>
        <div className="col-6">
        <img src = {appstore}/>
        </div>
        </div>
        </div>
        <div className="col-4">
        </div>
        </div>
        <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
        <p style={{textAlign:'center'}}>Scan QR Code</p>
        <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
        <div className="row">
        <div className="col-2"><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
        </div>
        <div className="col-2">
        </div>
        <div className="col-2">
        </div>
        </div>
        </div>
        <div className="col-4"></div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={()=>this.handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    )
    }
    //Modal Ends

    if((localStorage.getItem('helloToken1') || localStorage.getItem('helloToken'))){
    return (
      <React.Fragment>
      <Grid container spacing={4} className="homepageGridContainer">
      <div className="container-fluid">
      <div className="row">
      {/**cover image */}
        <div className="col-12 img-fluid"  >
        <img className =" centered-and-cropped" onClick={ () => this.handleShow()} alt={data.name} src = {data.cover_image}  />
        <img className="centered-and-play" onClick={ () => this.handleShow()} alt="play icon" src={play}/>
        </div>
        {/**logo and content */}
        <div className="col-12 mt-5">
        <div className="row">
            <div className="col-6 col-md-3">
                <img className="centered-and-fill" onClick={ () => this.handleShow()} alt={data.name} src={data.logo}/>
            </div>
            <div className="col-6 col-md-3"> 
            <div className="row">
              <div className="col-md-1">
              </div>
              <div className="col-md-11 col-12 mt-3">
                
              <h3 style={{fontWeight:"bold"}} > {data.name} </h3>
              <p style={{textTransform:"capitalize"}}>Genre : {data.genre}</p>
                <p>Released Year : {data.released_year}</p>
                {/**Staricon */}
                <ReactStars
                count={data.rating}
                size={24}
                activeColor="#e93e22"
                color="#e93e22"
              />
              </div>
            </div>
            </div>
        </div>
        </div>
        {/**Synposis */}
        <div className="col-12 mt-5 mb-5">
            <div style={{backgroundColor:'lightgray', marginBottom:4, padding:'10px 10px 10px 10px'}}>SYNOPSIS</div>
            
            <div style={{padding:'10px 10px 10px 10px'}} dangerouslySetInnerHTML={{__html: data.description}} />
            
        </div>
        

      </div> 
      </div>
      
             </Grid>
       </React.Fragment>
    )}else{
      return (
      <Grid container spacing={4} className="homepageGridContainer">
        <h3>Please Login To View Movies. </h3>
      </Grid>
      )
    }}
    
  
}

export default DetailMovie;