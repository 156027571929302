import React, { Component } from "react";
import axios from 'axios';
import "../../styles/common.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/videos.css";
import Loader1 from "../loader/loader";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
// import { CategoriesVideosList } from "../../connectivity/home/api.home";
//import { getCategoryId } from "./videoList";
// import { ViewCount } from "../../connectivity/home/api.increaseViewCount";

class liveTV extends Component {
  constructor(props){
    super(props);
    this.state= {
      recentlyAdded:[],
      recommended:[],
      channels:[],
      isLoading:false,
      
    }
  }

  fetchCategories =()=>{
    axios.get("https://mnm.nettvnepal.com.np/api/net-tv/channels/nettv")
    .then(res => { if(!this.unMounted){
                     this.setState({
                         recentlyAdded:res.data["Recently Added"],
                         recommended:res.data.Recommended,
                         channels:res.data.channels,
                         isLoading:true
                 })
             }
                     })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }
  handleShow = () => {
    this.setState({
        show:true
    })

  }
  handleClose = () =>{
      this.setState({
        show:false
      })
  }

  componentDidMount = () =>{
    this.fetchCategories()

  }

  componentWillUnmount = () => {
   this.Unmounted = true
}

  render() {
    
    const{recentlyAdded, recommended, channels, isLoading} = this.state;
    // console.log("object", recentlyAdded)
    {/**Channels */}
    const getChannels = channels.map(d =>
      <div>
      <h3 className="text-capitalize">{d.category_title}</h3>
            <div className="row">
            {d.channels.map(a=>
              <div className="col-md-2 col-sm-3 col-xs-3 py-3 px-3">
              <div className="geetInfo1">
              <img onClick={ () => this.handleShow()} src={a.logo} style={{ height:'195px'}} style = {{backgroundColor:'grey'}}/>
              <p className="truncate cardContent" style={{textAlign:'center'}}>{a.name}</p></div>
              </div> 
              )}
              </div>
              <hr/>
          </div>
          )
          {/**Recently Added */}
                const getRecentlyAdded= recentlyAdded.map(d =>
                  
                  <div className="col-md-2 col-sm-3 col-xs-3 py-3 px-3">
                  <div className="geetInfo1">
                          <img onClick={ () => this.handleShow()} src={d.logo} style={{ height:'200px'}} style = {{backgroundColor:'grey'}}/>
                          <p className="truncate cardContent" style={{textAlign:'center'}} >{d.name}</p></div>
                          </div>    
                      )

                  {/**Recommended */}
                 const getRecommended= recommended.map(d =>
                  
                        <div className="col-md-2 col-sm-3 col-xs-3 py-3 px-3">
                        <div className="geetInfo1">
                                <img onClick={ () => this.handleShow()} src={d.logo} style={{ height:'200px'}} style={{backgroundColor:'grey'}}/>
                                <p className="truncate cardContent" style={{textAlign:'center'}}>{d.name}</p></div>
                                </div>    
                            )
    // Modal Starts
    if(this.state.show){
      return (
        <div className="modal ">
        <Modal
         show={this.state.show} onHide={()=>this.handleClose()}
         centered
         
        >
        <Modal.Header closeButton >
        <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
        <div className = "row">
        <div className="col-4">
        </div>
        <div className="col-4">
        <div className = "row">
        <div className="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
        <img src = {playstore} /> 
        </a>
        </div>
        <div className="col-6">
        <img src = {appstore}/>
        </div>
        </div>
        </div>
        <div className="col-4">
        </div>
        </div>
        <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
        <p style={{textAlign:'center'}}>Scan QR Code</p>
        <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
        <div className="row">
        <div className="col-2"><img src = {logo} style={{height:'100px', width:'140px', justifyContent:'center'}}/>
        </div>
        <div className="col-2">
        </div>
        <div className="col-2">
        </div>
        </div>
        </div>
        <div className="col-4"></div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={()=>this.handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    )
    }
    //Modal Ends
    
    if(isLoading){
    return (
      <React.Fragment>
              <div className="textChange geetContainer">
              {/**Recently Added */}
              <h3 className="text-capitalize">Recently Added</h3>
              <div className="row">
              {getRecentlyAdded}
              </div>
              <hr/>

              {/**Recommended */}
              <h3 className="text-capitalize">Recommended</h3>
              <div className="row">
              {getRecommended}
              </div>
              <hr/>

              {/**Channels */}
              {getChannels}
              </div>
             
       </React.Fragment>
    );
    }
    else return <Loader1/>
  }
}

export default liveTV;
